import React from "react";
import * as dateFns from "date-fns";
import SignaturePad from "react-signature-canvas";

class StepOne extends React.Component {
  sigPad = {};

  clear = (e) => {
    e.preventDefault();
    this.props.clear().then(() => this.sigPad.clear());
  };

  trim = () => {
    this.props.trim(this.sigPad.getCanvas().toDataURL("image/png"));
  };

  render() {
    let invalidEmail =
      this.props.data.PersonalInformation.InvalidEmail === true;

    let semesters = this.props.Semesters.filter(
      (x) => x.name == "Winter 2025"
    ).map((sem, index) => {
      return (
        <div
          key={index}
          onClick={this.props.step1SemesterRender.bind(this, sem.sequential)}
        >
          <li className="bg-white">
            <label>
              {sem.name}
              <input type="radio" name="semester" />
            </label>
          </li>
        </div>
      );
    });

    return (
      <div
        className="multisteps-form__panel js-active"
        data-animation="slideHorz"
      >
        <div className="wizard-forms">
          <div className="inner clearfix">
            <div className="form-content pera-content">
              <div className="step-inner-content">
                <div className="row" style={{ marginLeft: "0" }}>
                  <span
                    className="step-box-icon "
                    style={{ marginBottom: "4rem" }}
                  >
                    <img
                      src={require("../../assets/img/logo_lumos.png").default}
                      alt=""
                    />
                  </span>
                </div>

                <h2>
                  The English program for those who don't have time to waste.
                </h2>

                <br />

                <p className="step-no " style={{ textAlign: "justify" }}>
                  Designed for those seeking fast and effective results, the
                  Swift program consists of 4 English levels, each lasting 90
                  hours. With 3 hours of classes per week, it offers the perfect
                  balance between high-quality learning and the convenience that
                  fits into your routine.{" "}
                </p>

                <h2>In which semester are you planning to start studying?</h2>

                <div className="services-select-option">
                  <ul>{semesters}</ul>
                </div>

                <h2>Campus Option</h2>

                <div className="services-select-option2 mb-60">
                  <ul>
                    <div onClick={this.props.step1CampusRender.bind(this, 1)}>
                      <li className="bg-white ">
                        <label>
                          Salt Lake City | UT 84107 | SEVIS Code:
                          DEN214F00899000
                          <input type="radio" name="campus" />
                        </label>
                      </li>
                    </div>
                    <div onClick={this.props.step1CampusRender.bind(this, 2)}>
                      <li className="bg-white">
                        <label>
                          Orem | UT 84097 | SEVIS Code: DEN214F00899001
                          <input type="radio" name="campus" />
                        </label>
                      </li>
                    </div>
                  </ul>
                </div>

                <h2>Classes</h2>

                <div className="services-select-option3 mb-60">
                  <ul>
                    {/* <div onClick={this.props.step1PeriodRender.bind(this, 1)}>
                      <li className="bg-white ">
                        <label>
                          Tuesday and Thursday | Morning | 10 AM to 11:30 AM
                          <input type="radio" name="period" />
                        </label>
                      </li>
                    </div>
                    <div onClick={this.props.step1PeriodRender.bind(this, 2)}>
                      <li className="bg-white">
                        <label>
                          Tuesday and Thursday | Evening | 6:00 PM to 7:30 PM
                          <input type="radio" name="period" />
                        </label>
                      </li>
                    </div> */}
                    <div onClick={this.props.step1PeriodRender.bind(this, 3)}>
                      <li className="bg-white">
                        <label>
                          Saturday | Morning | 9 AM to 12 PM
                          <input type="radio" name="period" />
                        </label>
                      </li>
                    </div>
                  </ul>
                </div>

                <h2>Personal Information</h2>

                <div className="row form-inner-area">
                  <div className="col-md-12">
                    <div className="wizard-form-input position-relative form-group has-float-label">
                      <label className="label-input">Full Legal Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="FullName"
                        value={this.props.data.PersonalInformation.FullName}
                        onChange={this.props.handleUserInput.bind(
                          this,
                          "Step1",
                          "PersonalInformation"
                        )}
                      />
                    </div>
                  </div>
                </div>

                <div className="row form-inner-area">
                  <div className="col-md-8">
                    <div className="wizard-form-input position-relative form-group has-float-label">
                      <label className="label-input">Email</label>

                      <input
                        type="email"
                        className={`form-control ${
                          invalidEmail === true ? " input-error " : ""
                        }`}
                        name="Email"
                        value={this.props.data.PersonalInformation.Email}
                        onChange={this.props.handleUserInput.bind(
                          this,
                          "Step1",
                          "PersonalInformation"
                        )}
                        onBlur={this.props.emailOnBlur.bind(this)}
                      />
                      {invalidEmail === true && (
                        <span className="text-danger">Invalid e-mail</span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="wizard-form-input position-relative form-group has-float-label">
                      <label className="label-input">Phone Number</label>
                      <input
                        type="text"
                        className="form-control"
                        name="PhoneNumber"
                        value={this.props.data.PersonalInformation.PhoneNumber}
                        onChange={this.props.handleUserInput.bind(
                          this,
                          "Step1",
                          "PersonalInformation"
                        )}
                      />
                    </div>
                  </div>
                </div>

                <div className="upload-documents">
                  <h2>Documents</h2>

                  <div className="upload-araa bg-white">
                    <input
                      type="hidden"
                      value=""
                      name="fileContent"
                      id="fileContent"
                    />
                    <input
                      type="hidden"
                      value=""
                      name="filename"
                      id="filename"
                    />
                    <div className="upload-icon float-left">
                      <i className="fas fa-cloud-upload-alt"></i>
                    </div>
                    <div className="upload-text">
                      <span>{this.props.data.FileName}</span>
                    </div>
                    <div className="upload-option text-center">
                      <label htmlFor="Step1Attach">Upload Documents</label>
                      <input
                        id="Step1Attach"
                        style={{ display: "none" }}
                        type="file"
                        onChange={this.props.onFileChange.bind(
                          this,
                          "Step1",
                          "FileContent",
                          "FileName"
                        )}
                      />
                    </div>
                  </div>
                  <span
                    style={{
                      fontStyle: "italic",
                      color: "gray",
                      textAlign: "justify",
                    }}
                  >
                    Upload a valid, unexpired form of ID. If you’re unable to
                    upload it, you can bring your ID to the Front Desk, and we
                    will make a copy for you. Please note that your information
                    and ID will remain confidential and will only be used for
                    school purposes.
                  </span>
                </div>

                <h2>Address (Inside the US)</h2>

                <div className="row form-inner-area">
                  <div className="col-md-12">
                    <div className="wizard-form-input position-relative form-group has-float-label">
                      <label className="label-input">Street Address</label>
                      <input
                        type="text"
                        className="form-control"
                        name="Street"
                        value={this.props.data.AddressInside.Street}
                        onChange={this.props.handleUserInput.bind(
                          this,
                          "Step1",
                          "AddressInside"
                        )}
                      />
                    </div>
                  </div>
                </div>

                <div className="row form-inner-area">
                  <div className="col-md-12">
                    <div className="wizard-form-input position-relative form-group has-float-label">
                      <label className="label-input">Address Line 2</label>
                      <input
                        type="text"
                        className="form-control"
                        name="Street2"
                        value={this.props.data.AddressInside.Street2}
                        onChange={this.props.handleUserInput.bind(
                          this,
                          "Step1",
                          "AddressInside"
                        )}
                      />
                    </div>
                  </div>
                </div>

                <div className="row form-inner-area">
                  <div className="col-md-6">
                    <div className="wizard-form-input position-relative form-group has-float-label">
                      <label className="label-input">City</label>
                      <input
                        type="text"
                        className="form-control"
                        name="City"
                        maxLength={"50"}
                        value={this.props.data.AddressInside.City}
                        onChange={this.props.handleUserInput.bind(
                          this,
                          "Step1",
                          "AddressInside"
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="wizard-form-input position-relative form-group has-float-label">
                      <label className="label-input">
                        State/Region/Province
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="State"
                        maxLength={"15"}
                        value={this.props.data.AddressInside.State}
                        onChange={this.props.handleUserInput.bind(
                          this,
                          "Step1",
                          "AddressInside"
                        )}
                      />
                    </div>
                  </div>
                </div>

                <div className="row form-inner-area">
                  <div className="col-md-6">
                    <div className="wizard-form-input position-relative form-group has-float-label">
                      <label className="label-input">Postal / Zip Code</label>
                      <input
                        type="text"
                        className="form-control"
                        name="Zip"
                        maxLength={"10"}
                        value={this.props.data.AddressInside.Zip}
                        onChange={this.props.handleUserInput.bind(
                          this,
                          "Step1",
                          "AddressInside"
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="wizard-form-input position-relative form-group has-float-label">
                      <label className="label-input">Country</label>
                      <input
                        type="text"
                        className="form-control"
                        name="Country"
                        value={this.props.data.AddressInside.CountryText}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>

                <h2>Emergency Contact</h2>

                <div className="row form-inner-area">
                  <div className="col-md-12">
                    <div className="wizard-form-input position-relative form-group has-float-label">
                      <label className="label-input">Full Legal Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="FullName"
                        value={this.props.data.EmergencyContact.FullName}
                        onChange={this.props.handleUserInput.bind(
                          this,
                          "Step1",
                          "EmergencyContact"
                        )}
                      />
                    </div>
                  </div>
                </div>

                <div className="row form-inner-area">
                  <div className="col-md-6">
                    <div className="wizard-form-input position-relative form-group has-float-label">
                      <label className="label-input">Relationship</label>
                      <input
                        type="text"
                        className="form-control"
                        name="Relationship"
                        value={this.props.data.EmergencyContact.Relationship}
                        onChange={this.props.handleUserInput.bind(
                          this,
                          "Step1",
                          "EmergencyContact"
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="wizard-form-input position-relative form-group has-float-label">
                      <label className="label-input">Phone Number</label>
                      <input
                        type="text"
                        className="form-control"
                        name="PhoneNumber"
                        value={this.props.data.EmergencyContact.PhoneNumber}
                        onChange={this.props.handleUserInput.bind(
                          this,
                          "Step1",
                          "EmergencyContact"
                        )}
                      />
                    </div>
                  </div>
                </div>

                <h2></h2>

                <div className="budget-area">
                  <p>
                    Please note that this application does not issue I-20
                    documents for international students requesting a visa or
                    for change of status purposes.
                  </p>
                  <div
                    className="opti-list"
                    onClick={this.props.handleCheckbox.bind(this, 1)}
                  >
                    <ul className="d-md-flex">
                      <li className="bg-white ">
                        <input type="checkbox" name="Agree1" />I agree
                      </li>
                    </ul>
                  </div>
                  <br />
                  <p>
                    A classroom will only open once we reach the minimum
                    required number of students. Please note that start and end
                    dates, as well as class openings and closures, may change
                    based on class capacity.
                  </p>
                  <div
                    className="opti-list"
                    onClick={this.props.handleCheckbox.bind(this, 2)}
                  >
                    <ul className="d-md-flex">
                      <li className="bg-white ">
                        <input type="checkbox" name="Agree1" />I agree
                      </li>
                    </ul>
                  </div>

                  <h2></h2>

                  <div
                    className="row form-inner-area"
                    style={{ marginTop: "2rem" }}
                  >
                    <div className="col-md-6">
                      <div className="wizard-form-input position-relative form-group has-float-label">
                        <label className="label-input">Signature</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="wizard-form-input position-relative form-group has-float-label float-right">
                        <button
                          color="danger"
                          className="btn btn-danger "
                          onClick={this.clear.bind(this)}
                        >
                          Clear Signature
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="row form-inner-area">
                    <div className="col-md-12">
                      <div className="sigContainer">
                        <SignaturePad
                          canvasProps={{
                            className: "sigPad",
                          }}
                          ref={(ref) => {
                            this.sigPad = ref;
                          }}
                          onEnd={this.trim.bind(this)}
                          clearOnResize={false}
                        />
                      </div>
                    </div>
                  </div>
                  <span
                    style={{
                      fontStyle: "italic",
                      color: "gray",
                      textAlign: "justify",
                    }}
                  >
                    By signing, I hereby certify that the above information, to
                    the best of my knowledge, is correct. I understand and agree
                    to abide by all the provisions set forth in the foregoing
                    enrollment agreement as presented in the school catalog.
                  </span>
                </div>

                {this.props.data.ErrorSubmit.length > 0 && (
                  <div className="row form-inner-area  ">
                    <div className="col-md-12">
                      <div className="wizard-form-input position-relative form-group has-float-label">
                        <div className="alert alert-danger" role="alert">
                          {this.props.data.ErrorSubmit}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="actions">
            <ul>
              <li>
                <span
                  className={`
                  ${
                    this.formValid() &&
                    this.props.data.SubmittingApplication === false
                      ? ""
                      : "button-disabled"
                  }`}
                  title="SUBMIT"
                  disabled={this.props.data.SubmittingApplication === true}
                  onClick={this.props.handleSubmit.bind(this)}
                >
                  SUBMIT <i className="fa fa-check"></i>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }

  formValid() {
    return (
      this.props.data.SemesterId > -1 &&
      this.props.data.CampusId > -1 &&
      this.props.data.PeriodId > -1 &&
      this.props.data.PersonalInformation.FullName.length > 0 &&
      this.props.data.PersonalInformation.Email.length > 0 &&
      this.props.data.PersonalInformation.InvalidEmail === false &&
      this.props.data.PersonalInformation.PhoneNumber.length > 0 &&
      this.props.data.AddressInside.Street.length > 0 &&
      this.props.data.AddressInside.City.length > 0 &&
      this.props.data.AddressInside.State.length > 0 &&
      this.props.data.AddressInside.Zip.length > 0 &&
      this.props.data.AddressInside.Country.length > 0 &&
      this.props.data.EmergencyContact.FullName.length > 0 &&
      this.props.data.EmergencyContact.Relationship.length > 0 &&
      this.props.data.EmergencyContact.PhoneNumber.length > 0 &&
      this.props.data.Agree1 === true &&
      this.props.data.Agree2 === true &&
      this.props.data.trimmedDataURL !== null &&
      this.props.data.trimmedDataURL.length > 0
    );
  }
}

export default StepOne;
